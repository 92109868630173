import { GetStaticProps } from 'next';
import { Question } from ':src/types';
import apiFetch from ':src/utils/apiFetch';
import LoggedOutHome from ':src/components/LoggedOutHome';

export type HomePageProps = {
  questions: Question[];
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const questions = await apiFetch<Question[]>('questions');
  return {
    props: {
      questions: questions!,
    },
    revalidate: 3600,
  };
};

export default function HomePage({ questions }: HomePageProps) {
  return <LoggedOutHome questions={questions} />;
}
