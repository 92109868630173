import React from 'react';
import { BarChart, Clock } from 'react-feather';
import type { Question } from ':src/types';

export type QuestionDetailsProps = {
  question: Question;
};

const getColorFromDifficulty = (difficulty: string) => {
  switch (difficulty) {
    case 'Easy':
      return '#006B3E';
    case 'Medium':
      return '#FFAA1C';
    case 'Hard':
    default:
      return '#ED2938';
  }
};

export default function QuestionDetails({
  question: { difficulty, timeLimit },
}: QuestionDetailsProps) {
  return (
    <div className="flex justify-between">
      <div className="text-gray-700">
        <div className="mb-2 flex items-center text-sm">
          <div>
            <BarChart
              className={`inline mr-1 w-5 difficulty-indicator--${difficulty}`}
              stroke={getColorFromDifficulty(difficulty)}
            />
            <span className="inline-block align-middle mt-0.5">
              {difficulty}
            </span>
          </div>
          <div className="mx-2">&middot;</div>
          <div>
            <Clock className="inline mr-1 w-5" />
            <span className="inline-block align-middle mt-0.5">
              {timeLimit} minutes
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
