import clsx from 'clsx';
import React from 'react';

export type BadgeColor = 'green' | 'red' | 'yellow' | 'default';

const colorToClassname = {
  green: 'bg-green-100 text-green-800',
  red: 'bg-red-100 text-red-800',
  yellow: 'bg-yellow-100 text-yellow-800',
  default: 'bg-accent text-white',
};

export type BadgeProps = {
  className?: string;
  color?: BadgeColor;
  text: string;
  size?: 'small' | 'medium';
};

export default function Badge({
  className,
  text,
  size = 'small',
  color = 'default',
}: BadgeProps) {
  const badgeClassName = clsx(
    'rounded-sm font-normal',
    colorToClassname[color],
    {
      'text-xs py-1 px-1.5': size === 'small',
      'text-sm py-1.5 px-2': size === 'medium',
    },
    className,
  );

  return <span className={badgeClassName}>{text}</span>;
}
