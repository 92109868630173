import clsx from 'clsx';

type Option = {
  value: string;
  display: string;
  disabled: boolean;
};

export type SelectProps<T extends string> = {
  className?: string;
  options: Option[];
  onChange: (value: T) => void;
  label?: string;
};

function Select<T extends string = string>({
  options,
  onChange,
  className,
  label,
  ...restProps
}: SelectProps<T>) {
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    onChange(value as T);
  };

  const fieldClassName = clsx(
    'form-select',
    'appearance-none',
    'block',
    'w-full',
    'px-3 py-1.5',
    'text-base font-normal text-gray-700',
    'bg-white bg-clip-padding bg-no-repeat',
    'border border-solid border-gray-300',
    'rounded',
    'transition ease-in-out',
    'm-0',
    'focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none',
  );

  return (
    <div className={className}>
      {label && (
        <label className="mb-2" htmlFor="select">
          {label}
        </label>
      )}
      <select
        id="select"
        className={fieldClassName}
        onChange={selectChange}
        {...restProps}
      >
        {options.map(({ value, display, disabled }) => {
          return (
            <option key={value} value={value} disabled={disabled}>
              {display}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Select;
