import React from 'react';
import { DIFFICULTY, DIFFICULTY_OPTIONS } from ':src/constants';
import Select from './Select';

export type DifficultySelectProps = {
  onSelect: (value: DIFFICULTY) => void;
};

export default function DifficultySelect({ onSelect }: DifficultySelectProps) {
  return (
    <Select<DIFFICULTY>
      className="basis-full md:basis-3/12"
      options={DIFFICULTY_OPTIONS}
      onChange={onSelect}
      label="Select Difficulty"
    />
  );
}
